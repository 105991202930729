body{
    background: white;
    height: 100vh;
    width: 100%;
    text-align: left;
}

.App-logo {
    display: block;
    padding: 20px;
    width: 200px;
}

.Actionnet-logo {
  display: block;
  padding: 0px;
  width: 200px;
}

.reg {
  text-align: right;
}
